
import { Component, Prop, Vue } from 'vue-property-decorator';

// import API
import { 
    campaignSetType, 
    campaignUnsetType 
} from "../../apiClient/campaignEndpoints";
import { 
    customerReadAllPretty, 
    customerReadPretty 
} from '@/apiClient/customerEndpoints';
import { 
    negativeActionApplyToCampaign, 
    negativeActionGetForCampaign, 
    negativeActionValidateForCampaign 
} from "@/apiClient/negativeActionEndpoints";
// imprort CONSTANTS
import { CONSTANTS } from "../../apiClient/constants";

// import components
import AdGroupView from '@/components/adGroup/AdGroupView.vue';
import { adGroupTypeReadAll } from '@/apiClient/adGroupTypeEndpoints';

@Component({
    components: { AdGroupView }
})
export default class CampaignView extends Vue {

    @Prop()
    private campaignId!: string;

    // ATTACH CONSTANTS
    private CONSTANTS = CONSTANTS;

    private campaign: any = {};

    private viewAdvanced: boolean = false;
    private advancedNegativeActions: any = false;


    private validated: boolean = false;
    private validating: boolean = false;

    private applied: boolean = false;
    private applying: boolean = false;

    // EXPANDED STATE
    private adGroupsExpanded: boolean = false;
    private negativeActionsExpanded: boolean = false;

    // NEGATIVE ACTIONS
    private negativeActionsList = [];
    private hash: any = null;

    // MOUNTED
    public mounted() {
        console.log("mounted()");

        for (let campaign of this.$store.state.customerCache.customer.campaigns) {
            if (campaign.campaignId === this.campaignId) {
                this.campaign = campaign;
                console.log(campaign);
                console.log("campaign set")
            };
        }
    }
    public loadCampaign() {
        for (let campaign of this.$store.state.customerCache.customer.campaigns) {
            if (campaign.campaignId === this.campaignId) {
                this.campaign = campaign;
            };
        }
    }

    public async setCampaignType(campaignId: string, campaignTypeId: number) {
        await campaignSetType(campaignId, campaignTypeId);
        await customerReadAllPretty();
        await customerReadPretty(this.$store.state.customerCache.customer.customerId);
        this.loadCampaign();
        this.$forceUpdate();
        
    };
    public async unsetCampaignType(campaignId: string) {
        await campaignUnsetType(campaignId);
        await customerReadAllPretty();
        await customerReadPretty(this.$store.state.customerCache.customer.customerId);
        this.loadCampaign();
        this.$forceUpdate();
    };

    public getRelevantCampaignTypeList() {
        let categoryName = this.$store.state.customerCache.customer.category.name;
        let relevantCampaignTypeList = [];
        for (let category of this.$store.state.categoryCache.categoryList) {
            if (category.name === categoryName) {
                for (let campaignType of this.$store.state.campaignTypeCache.campaignTypeList) {
                    if ((campaignType.name.split(":")[0] === category.name) || (campaignType.name.split(":")[0] === '*')) {
                        relevantCampaignTypeList.push(campaignType);
                    }
                }
            }
        }
        return relevantCampaignTypeList;
    };
    public getRelevantCategory(categoryId: any) {
        for (let category of this.$store.state.categoryCache.categoryList) {
            if (category.id === parseInt(categoryId)) {
                return category.name;
            }
        }
    };

    public async loadNegativeActions() {
        console.log(this.campaign.campaignId)
        this.advancedNegativeActions = await negativeActionGetForCampaign(this.campaign.campaignId.toString());
        if (this.advancedNegativeActions.negativeActions.length) {
            this.viewAdvanced = true;
        }
        else {
            this.viewAdvanced = false;
        }
        console.log("advancedNegativeActions", this.advancedNegativeActions);
    };

    public getCampaignType(campaignTypeId: any) {
        for (let campaignType of this.$store.state.campaignTypeCache.campaignTypeList) {
            if (campaignType.id === parseInt(campaignTypeId)) {
                return campaignType.name;
            }
        }
    };

    // NEGATIVE ACTIONS
    public async negativeKeywordsValidate() {
        this.validating = true;
        let result = await negativeActionValidateForCampaign(this.campaignId);
        if (result) {
            this.hash = result;
            this.validated = true;
        }
        else {
            this.validated = false;
        }
        this.validating = false;
    };
    public async negativeKeywordsHandle() {
        this.applying = true;
        let result = await negativeActionApplyToCampaign(this.campaignId, this.hash);
        if (result) {
            this.applied = true;
        }
        else {
            this.applied = false;
        }
        this.applying = false;
    };
    public async readNegativeActions() {
        this.negativeActionsList = (await negativeActionGetForCampaign(this.campaign.campaignId.toString())).negativeActions;
    };
    public async hideNegativeActions() {
        this.negativeActionsList = [];
    };

    // TOGGLE
    public async toggleAdGroups() {
        this.adGroupsExpanded = !this.adGroupsExpanded;
        if (this.adGroupsExpanded) {
            await adGroupTypeReadAll();
        }
    };
    public toggleNegativeActions() {
        this.negativeActionsExpanded = !this.negativeActionsExpanded;
    };
};
