
import { Component, Prop, Vue } from "vue-property-decorator";

// import API
import { customerReadAllPretty } from "@/apiClient/customerEndpoints";

@Component
export default class CustomerList extends Vue {

    // LOADING
    private isLoading: boolean = false;
    private hasLoaded: boolean = false;

    // ORDER
    private columnCustomerNameOrder: number = 1;
    private columnGoogleAdsId: number = 1;
    private columnCategoryOrder: number = 1;

    // BUTTON
    private btnLoad?: HTMLButtonElement;

    // MOUNTED
    public mounted() {
        console.log("mounted()");
        console.log(this.$router.currentRoute)

        this.hasLoaded = false;
        this.btnLoad = <HTMLButtonElement>document.getElementById("btnLoad");

        this.readAllCustomers();
    }

    // BUTTON BINDS
    public async readAllCustomers() {
        this.isLoading = true;
        this.hasLoaded = false;
        this.btnLoad?.classList.add("is-loading");
        // @ts-ignore
        this.btnLoad?.disabled = true;
        try {
            await customerReadAllPretty();
            this.hasLoaded = true;
        } catch (error) {
            console.log(error);
            this.hasLoaded = false;
        }
        this.isLoading = false;
        this.btnLoad?.classList.remove("is-loading");
        // @ts-ignore:
        this.btnLoad?.disabled = false;
    }
    public async viewCustomer(customerId: string) {
        this.$store.commit("updateCustomerCache", {
            customerId: customerId,
        });
        this.$store.commit("addComponent", { page: "customer", name: "CustomerView" });
        this.$store.commit("removeComponent", { page: "customer", name: "CustomerList" });
    }

    // API METHODS
    public async apiReadAllCustomers() {
        const response = await fetch(
            `${await this.$store.state
                .serverAddress}api/v1/customer/readpretty`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    Authorization: `Bearer ${this.$store.state.auth.key}`,
                },
            }
        );
        const jsonResponse = await response.json();
        if (jsonResponse.result == "success") {
            this.$store.commit("updateCustomerCache", {
                customerList: jsonResponse.data,
            });
        } else {
            this.$store.commit("updateCustomerCache", {
                customerList: [],
            });
        }
    }

    // TABLE SORT
    public sortColumnCustomerName() {
        this.columnCustomerNameOrder *= -1;
        let cacheCopy = this.$store.state.customerCache.customerList;
        let column = "customerName";
        // console.log(cacheCopy[0]);
        let compare = (a: any, b: any) => {
            if (a[column] < b[column]) {
                return (-1 * this.columnCustomerNameOrder);
            }
            if (a[column] > b[column]) {
                return (1 * this.columnCustomerNameOrder);
            }
            return 0;
        }
        cacheCopy.sort(compare);
    }
    public sortColumnGoogleAdsId() {
        this.columnGoogleAdsId *= -1;
        let cacheCopy = this.$store.state.customerCache.customerList;
        let column = "customerId";
        // console.log(cacheCopy[0]);
        let compare = (a: any, b: any) => {
            if (a[column] < b[column]) {
                return (-1 * this.columnGoogleAdsId);
            }
            if (a[column] > b[column]) {
                return (1 * this.columnGoogleAdsId);
            }
            return 0;
        }
        cacheCopy.sort(compare);
    }
    public sortColumnCategory() {
        this.columnCategoryOrder *= -1;
        let cacheCopy = this.$store.state.customerCache.customerList;
        let column = "categoryId";
        // console.log(cacheCopy[0]);
        let compare = (a: any, b: any) => {
            if (a[column] < b[column]) {
                return (-1 * this.columnCategoryOrder);
            }
            if (a[column] > b[column]) {
                return (1 * this.columnCategoryOrder);
            }
            return 0;
        }
        cacheCopy.sort(compare);
    }

    public isDateWorst(date: string) {
        if (date.length === 0) {
            return false;
        }
        let todayDate: any = new Date();
        let thenDate: any = new Date(date);
        const diff: any = Math.abs(todayDate - thenDate);
        const diffDays: any = Math.ceil(diff/(1000*60*60*24));
        if (diffDays > 28) {
            return true;
        }
        return false;
    }
    public isDateBad(date: string) {
        let todayDate: any = new Date();
        let thenDate: any = new Date(date);
        const diff: any = Math.abs(todayDate - thenDate);
        const diffDays: any = Math.ceil(diff/(1000*60*60*24));
        if (diffDays > 14) {
            return true;
        }
        return false;
    }
    public isDateMedium(date: string) {
        let todayDate: any = new Date();
        let thenDate: any = new Date(date);
        const diff: any = Math.abs(todayDate - thenDate);
        const diffDays: any = Math.ceil(diff/(1000*60*60*24));
        if ((diffDays <= 14) && (diffDays > 7)) {
            return true;
        }
        return false;
    }
    public isDateGood(date: string) {
        let todayDate: any = new Date();
        let thenDate: any = new Date(date);
        const diff: any = Math.abs(todayDate - thenDate);
        const diffDays: any = Math.ceil(diff/(1000*60*60*24));
        if (diffDays <= 7) {
            return true;
        }
        return false;
    }
}
